import type { CSSObject } from '@emotion/react';

export const heroHeadingStyle: CSSObject = {
  fontWeight: 900,
  fontStyle: 'italic',
  textTransform: 'uppercase',
  textShadow: `0 5px 2px rgba(0, 0, 0, 0.25)`,

  strong: {
    fontWeight: 900,
  },
};
